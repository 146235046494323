import $ from 'jquery';

function init() {
	"use strict";

	$(document).ready(function($){
        var dragging = false,
            scrolling = false,
            resizing = false;
        //cache jQuery objects
        var imageComparisonContainers = $('.js-diff');
        //check if the .js-diff is in the viewport
        //if yes, animate it
        checkPosition(imageComparisonContainers);
        $(window).on('scroll', function(){
            if( !scrolling) {
                scrolling =  true;
                ( !window.requestAnimationFrame )
                    ? setTimeout(function(){checkPosition(imageComparisonContainers);}, 100)
                    : requestAnimationFrame(function(){checkPosition(imageComparisonContainers);});
            }
        });

        //make the .js-diff-handle element draggable and modify .js-diff-image width according to its position
        imageComparisonContainers.each(function(){
            var actual = $(this);
            drags(actual.find('.js-diff-handle'), actual.find('.js-diff-image'), actual);
        });

        //upadate images label visibility
        $(window).on('resize', function(){
            if( !resizing) {
                resizing = true;
            }
        });

        function checkPosition(container) {
            container.each(function(){
                var actualContainer = $(this);
                if( $(window).scrollTop() + $(window).height()*0.5 > actualContainer.offset().top) {
                    actualContainer.addClass('is-visible');
                }
            });

            scrolling = false;
        }

        //draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
        function drags(dragElement, resizeElement, container, labelContainer, labelResizeElement) {
            dragElement.on("mousedown vmousedown", function(e) {
                dragElement.addClass('draggable');
                resizeElement.addClass('resizable');

                var dragWidth = dragElement.outerWidth(),
                    xPosition = dragElement.offset().left + dragWidth - e.pageX,
                    containerOffset = container.offset().left,
                    containerWidth = container.outerWidth(),
                    minLeft = containerOffset + 10,
                    maxLeft = containerOffset + containerWidth - dragWidth - 10;

                dragElement.parents().on("mousemove vmousemove", function(e) {
                    if( !dragging) {
                        dragging =  true;
                        ( !window.requestAnimationFrame )
                            ? setTimeout(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);}, 100)
                            : requestAnimationFrame(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);});
                    }
                }).on("mouseup vmouseup", function(){
                    dragElement.removeClass('draggable');
                    resizeElement.removeClass('resizable');
                });
                e.preventDefault();
            }).on("mouseup vmouseup", function() {
                dragElement.removeClass('draggable');
                resizeElement.removeClass('resizable');
            });
        }

        function animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement) {
            var leftValue = e.pageX + xPosition - dragWidth;
            //constrain the draggable element to move inside his container
            if(leftValue < minLeft ) {
                leftValue = minLeft;
            } else if ( leftValue > maxLeft) {
                leftValue = maxLeft;
            }

            var widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';

            $('.draggable').css('left', widthValue).on("mouseup vmouseup", function() {
                $(this).removeClass('draggable');
                resizeElement.removeClass('resizable');
            });

            $('.resizable').css('width', widthValue);
            dragging =  false;
        }

        
        $('.toggler').click(function(){
            $('body').toggleClass('scroll-none');
        });
        
        var carousel = function ($el) {
            var LOOP_EVERY = 5000;
        
            var autoTimeout, index, $items, totalItems, width;
        
            /**
             * Adds event listeners to element.
             */
            var addListeners = function () {
                $el.on('click', next);
                $(window).on('resize', function () {
                    cacheWidth();
                    setToIndex();
                })
            };
        
            var cacheWidth = function () {
                width = $el.width();
            };
        
            /**
             * Goes to the next item in the carousel.
             */
            var next = function () {
                index++;
        
                if (index === totalItems) {
                    index = 0;
                }
        
                setToIndex();
            };
        
            /**
             * Resets the auto timeout.
             */
            var resetAuto = function () {
                if (autoTimeout) {
                    window.clearTimeout(autoTimeout);
                }
        
                autoTimeout = window.setTimeout(next, LOOP_EVERY);
            };
        
            /**
             * Sets carousel to the index position.
             */
            var setToIndex = function () {
                $items.css('transform', 'translateX(-' + (index * width).toString() + 'px)');
                resetAuto();
            };
        
            // get children in carousel
            $items = $el.find('.js-carousel-items');
        
            // cache properties
            width = $el.width(),
            index = 0;
            totalItems = $items.children().length;
        
            addListeners();
            resetAuto();
        };
        
        $('.js-carousel').each(function () {
            carousel($(this));
        });
    });    
}

export default { init }
