import $ from 'jquery';

function init() {
	"use strict";

    var CSS = {
        activeStep: 'is-active',
        selectedOption: 'is-selected'
    };

	$(document).ready(function($){
        var dom = {
            $purchaseForm: $('.js-purchase-form'),
            $retailerData: $('.js-purchase-form-data')
        };

        var state = {
            selectedPlatform: ''
        };

        if (dom.$purchaseForm.length === 0 || dom.$retailerData.length === 0) {
            return;
        }

        state.retailers = window.JSON.parse(dom.$retailerData.val());

        var checkIfOnlySingleRetailer = function () {
            if (state.matchedRetailers.length !== 1) {
                return false;
            }

            if (window.dataLayer)
            {
                window.dataLayer.push({
                    'event': "buy_now",
                    'platform': state.matchedRetailers[0].platform.toLowerCase(),
                    'version': state.matchedRetailers[0].version.toLowerCase(),
                    'location': state.matchedRetailers[0].location.toLowerCase(),
                    'storename': state.matchedRetailers[0].storename.toLowerCase(),  
                });
            }

            return true;
        };

        var onSelectCountry = function (ev) {
            state.selectedCountry = $(ev.currentTarget).attr('data-value');

            update();
            setCountrySelectedState();

            if (checkIfOnlySingleRetailer()) {
                window.open(state.matchedRetailers[0].url, '_blank');
                return;
            }

            updateRetailers();
            dom.$retailerStep.addClass(CSS.activeStep);
        };

        var onSelectPlatform = function (ev) {
            state.selectedPlatform = $(ev.currentTarget).attr('data-value');
            state.selectedVersion = '';
            state.selectedCountry = '';

            dom.$countryStep.removeClass(CSS.activeStep);
            dom.$retailerStep.removeClass(CSS.activeStep);

            update();
            setPlatformSelectedState();

            if (checkIfOnlySingleRetailer()) {
                window.open(state.matchedRetailers[0].url, '_blank');
                return;
            }

            updateVersionOptions();

            dom.$versionStep.addClass(CSS.activeStep);
        };

        var onSelectVersion = function (ev) {
            state.selectedVersion = $(ev.currentTarget).attr('data-value');
            state.selectedCountry = '';

            dom.$retailerStep.removeClass(CSS.activeStep);

            update();
            setVersionSelectedState();

            if (checkIfOnlySingleRetailer()) {
                window.open(state.matchedRetailers[0].url, '_blank');
                return;
            }
            
            updateCountryOptions();

            dom.$countryStep.addClass(CSS.activeStep);
        };

        var setCountrySelectedState = function () {
            dom.$countryStepOptions.each(function () {
                if ($(this).attr('data-value') === state.selectedCountry) {
                    $(this).addClass(CSS.selectedOption);
                    return;
                }

                $(this).removeClass(CSS.selectedOption);
            });
        };

        var setPlatformSelectedState = function () {
            dom.$platformStepOptions.each(function () {
                if ($(this).attr('data-value') === state.selectedPlatform) {
                    $(this).addClass(CSS.selectedOption);
                    return;
                }

                $(this).removeClass(CSS.selectedOption);
            });
        };

        var setVersionSelectedState = function () {
            dom.$versionStepOptions.each(function () {
                if ($(this).attr('data-value') === state.selectedVersion) {
                    $(this).addClass(CSS.selectedOption);
                    return;
                }

                $(this).removeClass(CSS.selectedOption);
            });
        };

        var trackRetailer = function (e) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    'event': "buy_now",
                    'platform': state.matchedRetailers[0].platform.toLowerCase(),
                    'version': state.matchedRetailers[0].version.toLowerCase(),
                    'location': state.matchedRetailers[0].location.toLowerCase(),
                    'storename':  $(e.currentTarget).attr('data-name').toLowerCase(),  
                });
            }

            return true;
        };

        var update = function () {
            state.matchedRetailers = [];

            for (var i = 0; i < state.retailers.length; i++) 
            {
                if (state.selectedPlatform && state.retailers[i].platform !== state.selectedPlatform) {
                    continue;
                }

                if (state.selectedVersion && state.retailers[i].version !== state.selectedVersion) {
                    continue;
                }

                if (state.selectedCountry && state.retailers[i].location !== state.selectedCountry) {
                    continue;
                }

                state.matchedRetailers.push(state.retailers[i]);
            }
        };

        var updateCountryOptions = function () {
            var uniqueCountries = [];
            
            for (var i = 0; i < state.matchedRetailers.length; i++) 
            {
                uniqueCountries.push(state.matchedRetailers[i].location);
            }

            dom.$countryStepOptions.each(function () {
                $(this).prop('disabled', uniqueCountries.indexOf($(this).attr('data-value')) === -1);

                if ($(this).prop('disabled')) {
                    $(this).parent().addClass('is-disabled');
                } 
                else {
                    $(this).parent().removeClass('is-disabled');
                }

                if ($(this).attr('data-value') === state.selectedCountry) {
                    $(this).addClass(CSS.selectedOption);
                    return;
                }

                $(this).removeClass(CSS.selectedOption);
            });
        };

        var updateRetailers = function() {
            dom.$retailerOptions.html('');

            var html = '';

            for (var i = 0; i < state.matchedRetailers.length; i++) {
                html += '<li>';
                html += '<a class="purchase-form__step-option purchase-form__step-option--retailer cta cta--option cta--option-link js-purchase-form-retailer-link" data-name="' + state.matchedRetailers[i].storename + '" target="_blank" href="' + state.matchedRetailers[i].url + '">';
                html += state.matchedRetailers[i].storename;
                html += '</a>'
                html += '</li>';
            }

            dom.$retailerOptions.html(html);

            dom.$retailerOptions.find('.js-purchase-form-retailer-link').on('click', trackRetailer);
        };

        var updateVersionOptions = function () {
            var uniqueVersions = [];
            
            for (var i = 0; i < state.matchedRetailers.length; i++) 
            {
                uniqueVersions.push(state.matchedRetailers[i].version);
            }

            dom.$versionStepOptions.each(function () {
                $(this).prop('disabled', uniqueVersions.indexOf($(this).attr('data-value')) === -1);

                if ($(this).attr('data-value') === state.selectedVersion) {
                    $(this).addClass(CSS.selectedOption);
                    return;
                }

                $(this).removeClass(CSS.selectedOption);
            })
        };

        dom.$platformStep = dom.$purchaseForm.find('.js-purchase-form-platform-step');
        dom.$platformStepOptions = dom.$platformStep.find('.js-purchase-form-option');
        dom.$versionStep = dom.$purchaseForm.find('.js-purchase-form-version-step');
        dom.$versionStepOptions = dom.$versionStep.find('.js-purchase-form-option');
        dom.$countryStep = dom.$purchaseForm.find('.js-purchase-form-country-step');
        dom.$countryStepOptions = dom.$countryStep.find('.js-purchase-form-option');
        dom.$retailerStep = dom.$purchaseForm.find('.js-purchase-form-retailer-step');
        dom.$retailerOptions = dom.$retailerStep.find('.js-purchase-form-retailer-options');

        dom.$platformStepOptions.on('click', onSelectPlatform);
        dom.$versionStepOptions.on('click', onSelectVersion);
        dom.$countryStepOptions.on('click', onSelectCountry);
    });
}

export default { init }
